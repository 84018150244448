window.staging_glass =
  window.staging_glass ||
  (() => {
    let staging_glassq = (window.staging_glassq = window.staging_glassq || [])

    // Intercept dataLayer.push for all events
    if (window.dataLayer && Array.isArray(window.dataLayer)) {
      const originalPush = window.dataLayer.push

      window.dataLayer.push = function (event) {
        originalPush.apply(this, arguments)

        // Skip if no event or if it's a GTM event (starts with gtm.)
        if (!event?.event || event.event.startsWith('gtm.')) return

        // Handle purchase events specially
        if (event.event === 'purchase' && event.ecommerce?.items) {
          event.ecommerce.items.forEach((item) =>
            staging_glassq.push([
              'revenue',
              {
                amount: event.ecommerce.value || 0,
                currency: event.ecommerce.currency || 'USD',
                product: item.item_name || item.item_id || 'Unknown',
                quantity: item.quantity || 1,
              },
            ]),
          )
        } else {
          // Handle all other events as custom events
          let eventData = { event_name: event.event, event_type: 'custom', ...event }
          delete eventData.event
          // Skip if event name is empty, null or undefined
          if (!eventData.event_name?.trim()) return
          staging_glassq.push(['custom_event', eventData])
        }
      }
    }

    var script = document.currentScript || document.querySelector('script[data-site]')
    siteId = script.getAttribute('data-site')
    var heatMap = []
    var pageInfo = {}
    var events = []
    var maxScrollDepth = 0
    var external
    var trackFileExtensions = [
      'pdf',
      'xlsx',
      'docx',
      'txt',
      'rtf',
      'csv',
      'exe',
      'key',
      'pps',
      'ppt',
      'pptx',
      '7z',
      'pkg',
      'rar',
      'gz',
      'zip',
      'avi',
      'mov',
      'mp4',
      'mpeg',
      'wmv',
      'midi',
      'mp3',
      'wav',
      'wma',
      'dmg',
    ]

    excludedDomains = [
      'gtm-msr.appspot.com',
      ...((
        script.getAttribute('data-excluded-domains') || script.getAttribute('excluded-domains')
      )?.split(',') ?? []),
    ]

    function isTrackingEnabled() {
      const { hostname, pathname } = window.location
      return pathname && hostname && siteId && !excludedDomains.includes(hostname)
    }

    function initializeScrollDepth() {
      const { scrollHeight } = document.documentElement
      const viewportHeight = window.innerHeight

      maxScrollDepth = Math.min((viewportHeight / scrollHeight) * 100, 100)
    }
    function updateScrollDepth() {
      const { scrollHeight } = document.documentElement
      const viewportHeight = window.innerHeight

      const currentScrollDepth =
        scrollHeight === 0
          ? null
          : scrollHeight <= viewportHeight
          ? 100 // Non-scrollable or fully visible page
          : (Math.min(window.scrollY + viewportHeight, scrollHeight) / scrollHeight) * 100
      maxScrollDepth = Math.min(Math.max(maxScrollDepth, currentScrollDepth), 100)
    }

    const handleExternalLink = () => {
      document.querySelectorAll('a').forEach((link) => {
        link.addEventListener('click', function (event) {
          const linkUrl = new URL(link.getAttribute('href'), window.location.href)
          const currentHostname = window.location.hostname
          if (!linkUrl) return

          // ignore file downloads
          const fileExtension = linkUrl.pathname.split('.').pop().toLowerCase()
          if (trackFileExtensions.includes(fileExtension)) {
            return
          }
          // track only external links
          if (
            linkUrl.hostname !== currentHostname &&
            link.href !== undefined &&
            link.href !== 'undefined'
          ) {
            event.preventDefault()
            external = linkUrl
            staging_glassq.push([
              'external_link',
              {
                external_link: external,
                event_type: 'external_link',
              },
            ])

            // window.location.href = link.href

            // Check if link should open in a new tab
            if (link.target === '_blank') {
              window.open(link.href, '_blank')
            } else {
              window.location.href = link.href
            }
          }
        })
      })
    }

    const handleFileDownload = () => {
      function trackDownload(url) {
        var fileUrl = url
        staging_glassq.push(['file_download', { fileUrl }])
      }

      // Track Downloads from <a/> clicks
      document.addEventListener('click', (e) => {
        var target = e.target.closest('a')
        if (target && target.href) {
          var fileExt = target.href.split('.').pop()
          if (trackFileExtensions.includes(fileExt)) {
            trackDownload(target.href)
          }
        }
      })
    }

    const handleFormSubmit = () => {
      const getFormName = (form) => {
        if (form.getAttribute('id')) return form.getAttribute('id')
        else if (form.getAttribute('name')) return form.getAttribute('name')
        else return `Form on ${window.location.pathname}`
      }

      document.querySelectorAll('form').forEach((form) => {
        form.addEventListener('submit', function (event) {
          const form_name = getFormName(form)
          staging_glassq.push([
            'form_submit',
            {
              form_name,
            },
          ])
        })
      })
    }

    const sendAnalyticsBeacon = (params) => {
      if (!isTrackingEnabled()) {
        return
      }
      const events = params.events ?? []
      params.events = events.length
        ? encodeURIComponent(JSON.stringify(events))
        : encodeURIComponent('[]')

      params.cid = Math.floor(1e8 * Math.random()) + 1
      params.sid = siteId
      const searchParams = new URLSearchParams(params).toString()
      const url = 'https://staging.alice.glassanalytics.com/' + '?' + searchParams;

      navigator.sendBeacon(url)
    }

    const historyBasedTracking = () => {
      if (history) {
        initializeScrollDepth()
        handleExternalLink()
        handleFormSubmit()
        handleFileDownload()
        // handleRrWeb()

        const originalPushState = history.pushState
        history.pushState = function (...args) {
          const result = originalPushState.apply(history, args)
          window.dispatchEvent(new Event('pushstate'))
          window.dispatchEvent(new Event('locationChange'))
          return result
        }

        window.addEventListener('popstate', () => {
          window.dispatchEvent(new Event('locationChange'))
        })

        window.addEventListener('locationChange', () => {
          const navigationTiming = performance.getEntriesByType('navigation')[0]
          const timeSpent = (performance.now() - navigationTiming.domContentLoadedEventEnd) / 1000

          staging_glassq.push([
            'page_view',
            {
              ...pageInfo,
              scroll_depth: maxScrollDepth,
              time_spent: timeSpent ?? 0,
              heat_map: JSON.stringify(heatMap),
              timestamp: Date.now(),
              viewport_height:document.documentElement.scrollHeight,
              viewport_width:document.documentElement.clientWidth
            },
          ])
          sendAnalyticsBeacon({ events: staging_glassq })
          // handleTrack({
          //   ...pageInfo,
          //   scrollDepth,
          //   timeSpent,
          //   custom_events: glassq,
          // })
        })
      }
    }

    function handleTrack({
      timeSpent,
      pathname,
      referrer,
      hostname,
      scrollDepth,
      external_link,
      event_type,
      searchParamsObj,
      rrweb_event,
      custom_events,
    }) {
      if (!isTrackingEnabled()) {
        return
      }

      const payload = {
        p: pathname,
        h: hostname,
        vh: document.documentElement.scrollHeight,
        vw: document.documentElement.clientWidth,
        t: timeSpent ?? 0,
        sid: siteId,
        hm: JSON.stringify(heatMap),
        sd: scrollDepth,
        qs: JSON.stringify(searchParamsObj),
        et: event_type ?? 'page_view',
        ...(custom_events && { ce: JSON.stringify(custom_events) }),
        ...(rrweb_event && { rr: JSON.stringify(rrweb_event) }),
        ...(external_link && { el: external_link }),
        ...(referrer && { r: referrer }),
        timestamp: Date.now(),
      }

      sendAnalyticsBeacon(payload)
    }
    function handleRrWeb() {
      if (rrweb) {
        const stopRecording = rrweb.record({
          emit(event) {
            events.push(event)
          },
        })
      }
    }

    // function handleTrackEvent(event_type, payload = {}) {
    //   const { hostname, pathname } = window.location
    //   const searchParams = new URLSearchParams(window.location.search)

    //   const eventPayload = {
    //     p: pathname,
    //     h: hostname,
    //     r: document.referrer,
    //     et: event_type,
    //     qs: JSON.stringify(Object.fromEntries(searchParams)),
    //     payload: JSON.stringify(payload),
    //     sid: siteId,
    //     timestamp: Date.now(),
    //   }

    //   sendAnalyticsBeacon(eventPayload)
    // }

    // function processCommand([command, ...args]) {
    //   switch (command) {
    //     case 'track':
    //       handleTrack(...args)
    //       break
    //     case 'form_submit':
    //       handleTrackEvent('form_submit', ...args)
    //       break
    //     case 'file_download':
    //       handleTrackEvent('file_download', ...args)
    //       break
    //     case 'custom_event':
    //       handleTrackEvent('custom_event', ...args)
    //       break
    //     case 'revenue':
    //       handleRevenue(...args)
    //       break
    //     default:
    //       console.warn(`Glass Analytics (https://glassanalytics.com): Unknown command "${command}"`)
    //       break
    //   }
    // }

    window.addEventListener('scroll', updateScrollDepth)

    if (void 0 !== history) {
      const searchParams = new URLSearchParams(window.location.search)
      const searchParamsObj = Object.fromEntries(searchParams)
      pageInfo = {
        host: window.location.hostname,
        path: window.location.pathname,
        ...(document.referrer && { referer: document.referrer }),
        ...searchParamsObj,
      }
      historyBasedTracking()
    } else {
      console.warn(
        'Glass Analytics (https://glassanalytics.com): History API not supported. Tracking may not work as expected.',
      )
    }

    document.addEventListener('click', (event) => {
      heatMap.push({ x: event.pageX, y: event.pageY })
    })

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => {
        initializeScrollDepth()
        handleExternalLink()
        handleFormSubmit()
        handleFileDownload()
        // handleRrWeb()
      })
    } else {
    }

    // function handleRevenue(params) {
    //   const { amount, currency, product, quantity } = params
    //   handleTrackEvent('revenue', {
    //     amount: Number(amount) ?? 0,
    //     currency: String(currency || '')
    //       .slice(0, 3)
    //       .toUpperCase(),
    //     product: String(product || '').slice(0, 100),
    //     quantity: Number(quantity) ?? 0,
    //   })
    // }

    window.addEventListener('beforeunload', () => {
      const navigationTiming = performance.getEntriesByType('navigation')[0]
      const timeSpent = (performance.now() - navigationTiming.domContentLoadedEventEnd) / 1000

      // Send the page_view event first
      staging_glassq.push([
        'page_view',
        {
          ...pageInfo,
          scroll_depth: maxScrollDepth,
          time_spent: timeSpent ?? 0,
          heat_map: JSON.stringify(heatMap),
          timestamp: Date.now(),
          viewport_height:document.documentElement.scrollHeight,
          viewport_width:document.documentElement.clientWidth
        },
      ])

      sendAnalyticsBeacon({ events: staging_glassq })
      // handleTrack({
      //   ...pageInfo,
      //   scrollDepth: maxScrollDepth,
      //   timeSpent,
      //   event_type: 'page_view',
      //   custom_events: glassq,
      // })
    })

    return {}
  })()
